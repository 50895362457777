import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { Result, Button, Typography } from "antd"
import { compose } from 'recompose'
import { inject, observer } from 'mobx-react'

import LightContainer from "../components/LightContainer"
import withRoot from '../withRoot'
import { EnvironmentOutlined } from "@ant-design/icons"
import { sleep } from "../utils/slug";

const SuccessApplication = ({
    location,
    appStore
}) => {
    const paramString = location.search.slice(1);
    const searchParams = new URLSearchParams(paramString);

    const leasingId = searchParams.get("leasingId");

    const [pdf, setPdf] = useState(undefined);
    const [address, setAddress] = useState(undefined);

    const loadLeasing = async () => {
        const maxCount = 4
        let currentCount = 0
        while(currentCount<maxCount){
            currentCount++
            const leasingDetails = await appStore.clientApi.getLeasingDetail_(leasingId)
            if (leasingDetails.status === 'submitted' && leasingDetails.applicationForm) {
                currentCount = 4
                setAddress(leasingDetails[":listing"]["name"])
                setPdf(leasingDetails.applicationForm)
            }
            await sleep(5000)
        }
    }

    useEffect(() => {
        if (leasingId) {
            loadLeasing()
        }
    }, [searchParams])

    return (
        <LightContainer>
            <Typography.Title level={2}>Application</Typography.Title>
            <Result
                status="success"
                title="Your application has been submitted!"
                subTitle={
                    <>
                        {address && <Typography.Title level={5} style={{ marginTop: 24 }}><EnvironmentOutlined /> {address}</Typography.Title>}
                        {/* <Typography.Paragraph type="secondary">A copy of your application will be sent to your registration email.</Typography.Paragraph> */}
                    </>
                }
                extra={[
                    <Button type="primary" key="back" onClick={() => navigate("/")}>
                        Back
                    </Button>,
                    <Button key="pdf" loading={!pdf} onClick={() => window.open(pdf, "_blank")}>View PDF</Button>,
                ]}
            />
        </LightContainer>
    )
}

export default compose(withRoot, inject('appStore', 'uiStore'), observer)(SuccessApplication)
